import React, { useContext, useEffect, useRef, useState } from "react";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { Document, Page, pdfjs } from "react-pdf";

import anime from "animejs";

import { PlayerContext } from "module/player/PlayerContext";
import { usePlayerControl } from "module/player/PlayerControlHook";
import { useDocumentUrl } from "module/document/DocumentUrlHook";
import { Grid } from "@mui/material";

import useDebouncedEffect from "use-debounced-effect";
import { useSwipeable } from "react-swipeable";
import { useEffectOnce, useWindowSize } from "react-use";
import { min } from "lodash";
import { useFullscreenElement } from "module/common/hook/FullscreenElementHook";
import { LinksOverlay } from "../LinksOverlay";
import { LinksContext } from "module/player/LinksContext";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

export const PDFPlayer: React.FC<{
  hidden: boolean;
  onLoadSucces(): void;
}> = (props) => {
  const playerContext = useContext(PlayerContext);

  const documentsNavigationContext = useContext(LinksContext);

  const { getPage, gotoPage, nextPage, previousPage, getZoomLevel } =
    usePlayerControl();

  const { getCachedPlayerUrl } = useDocumentUrl();

  const { height, width } = useWindowSize();

  const { fullscreenElement } = useFullscreenElement();

  const parentContainerRef = useRef<HTMLDivElement>(null);

  const [originWidth, setOriginWidth] = useState<number>();
  const [originRatio, setOriginRatio] = useState<number>(1);
  const [pdfWidth, setPdfWidth] = useState<number>(0);
  const [pdfFile, setPdfFile] = useState<{
    url: string;
    withCredentials: true;
  }>();

  const [pageNumber, setPageNumber] = useState<number>(getPage());
  const [translateX, setTranslateX] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffectOnce(() => {
    getCachedPlayerUrl(playerContext.document!, {
      forceLimited: true,
    }).then((result) => setPdfFile({ url: result, withCredentials: true }));
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => nextPage(),
    onSwipedRight: () => previousPage(),
  });

  useDebouncedEffect(
    () => {
      anime({
        targets: "#page",
        opacity: 0,
        easing: "linear",
        duration: 10,
      }).finished.then(() => setPageNumber(getPage()));
    },
    200,
    [getPage]
  );

  useEffect(() => {
    setLoaded(false);
  }, [getPage]);

  useEffect(() => {
    const heightOffset = !!fullscreenElement ? 0 : 120;
    originWidth &&
      setPdfWidth(
        originWidth && originWidth > width
          ? min([width, (height - heightOffset) * originRatio])!
          : originRatio > 1
            ? min([width, (height - heightOffset) * originRatio])!
            : (height - heightOffset) * originRatio
      );
  }, [height, width, fullscreenElement, originRatio, originWidth]);

  useEffect(() => {
    setTranslateX(
      parentContainerRef.current &&
        parentContainerRef.current.clientWidth < width
        ? (width - parentContainerRef.current.clientWidth) / 2
        : 0
    );
    // Keep all dependencies below (even those not used in the useEffect body) to trigger useEffect whenever necessary
  }, [pdfWidth, getZoomLevel, width]);

  return (
    <Grid
      container
      justifyContent={"center"}
      id="page"
      style={{ width: "auto", display: props.hidden ? "none" : undefined }}
      ref={parentContainerRef}
    >
      <Grid
        {...handlers}
        container
        item
        style={{
          width: "auto",
          transform: `translate3d(${translateX}px, 0, 0)`,
        }}
        justifyContent={"center"}
      >
        <Document
          file={pdfFile}
          externalLinkTarget={"_blank"}
          noData={""}
          error={""}
          onLoadSuccess={props.onLoadSucces}
          onItemClick={(event) => {
            console.log(event);
            gotoPage(parseInt(event.pageNumber));
          }}
        >
          <Page
            object-fit="fill"
            key={String(pageNumber)}
            pageNumber={pageNumber}
            renderAnnotationLayer={
              documentsNavigationContext.getLinksCountByPage() === 0
            }
            renderTextLayer
            renderInteractiveForms
            scale={(getZoomLevel() || 100) / 100}
            height={height}
            width={pdfWidth}
            onRenderSuccess={() => {
              anime({
                targets: "#page",
                opacity: 1,
                translateX: 0,
                easing: "linear",
                duration: 10,
              });
              setLoaded(true);
            }}
            onLoadSuccess={(pdf: pdfjs.PDFPageProxy) => {
              setOriginWidth(() => pdf.getViewport({ scale: 1 }).width);
              setOriginRatio(
                () =>
                  pdf.getViewport({ scale: 1 }).width /
                  pdf.getViewport({ scale: 1 }).height
              );
            }}
          />
        </Document>
        {documentsNavigationContext.getLinksCountByPage() > 0 && loaded && (
          <LinksOverlay width={pdfWidth} height={pdfWidth / originRatio} />
        )}
      </Grid>
    </Grid>
  );
};
